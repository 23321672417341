//core
.p-checkbox {
  position: relative;
  display: inline-flex;
  user-select: none;
  vertical-align: bottom;
}

.p-checkbox-input {
  cursor: pointer;
}

.p-checkbox-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

//theme
.p-tristatecheckbox {
  &.p-variant-filled {
    .p-checkbox-box {
      background-color: $inputFilledBg;
    }

    &.p-highlight {
      .p-checkbox-box {
        background: $checkboxActiveBg;
      }
    }

    &:not(.p-disabled) {
      &:has(.p-checkbox-input:hover) {
        .p-checkbox-box {
          background-color: $inputFilledHoverBg;
        }

        &.p-highlight {
          .p-checkbox-box {
            background: $checkboxActiveHoverBg;
          }
        }
      }
    }
  }
}
