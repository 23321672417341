/* Customizations to the designer theme should be defined here */


@mixin list-item-hover() {
  box-shadow: inset 0 0 0 .1rem $primaryColorAlt;
}

@layer primereact {
  //inputtext
  .p-inputtext {
    &:enabled:hover {
      background-color: $white100;
    }

    &:enabled:focus {
      background-color: $white;
    }
  }

  //chips
  .p-chips {
    .p-chips-multiple-container {
      &:not(.p-disabled):hover {
        background-color: $white100;
      }

      &:not(.p-disabled).p-focus {
        background-color: $white;
      }
    }
  }

  //autocomplete
  .p-autocomplete {
    .p-autocomplete-panel {
      .p-autocomplete-item {
        &:not(.p-disabled):hover {
          @include list-item-hover();
        }
      }
    }

    &.p-autocomplete-multiple {
      .p-autocomplete-multiple-container {
        &:not(.p-disabled):hover {
          background-color: $white100;
        }
      }
    }
  }

  //dropdown
  .p-dropdown {
    &:not(.p-disabled):hover {
      background-color: $white100;
    }
  }

  .p-dropdown-panel {
    .p-dropdown-items {
      .p-dropdown-item {
        &:not(.p-highlight):not(.p-disabled):hover {
          @include list-item-hover();
        }
      }
    }
  }

  //listbox
  .p-listbox {
    &:not(.p-disabled) {
      .p-listbox-item {
        &:not(.p-disabled):hover {
          @include list-item-hover();
        }
      }
    }
  }

  //multiselect
  .p-multiselect-panel {
    .p-multiselect-items {
      .p-multiselect-item {
        &:not(.p-highlight):not(.p-disabled):hover {
          @include list-item-hover();
        }
      }
    }
  }

  .p-multiselect {
    &:not(.p-disabled):hover {
      background-color: $white100;
    }
  }

  //button
  .p-button {
    font-weight: 500;
  }

  //radio
  .p-radiobutton {
    .p-radiobutton-box {
      &:not(.p-disabled):not(.p-highlight):hover {
        background-color: $white300;;
      }

      &:not(.p-disabled).p-focus {
        border-color: transparent;
      }
    }
  }

  //checkbox
  .p-checkbox {
    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          background-color: $white300;
        }

        &.p-focus {
          border-color: transparent;
        }
      }
    }
  }

  //accordion
  .p-accordion {
    .p-accordion-header {
      &:not(.p-highlight):not(.p-disabled):hover {
        &:not(.p-disabled) {
          .p-accordion-header-link {
            &:focus {
              @include list-item-hover();
            }
          }
        }

        .p-accordion-header-link {
          @include list-item-hover();
        }
      }

      &:not(.p-disabled).p-highlight {
        &:hover {
          .p-accordion-header-link {
            @include list-item-hover();
          }
        }
      }
    }
  }

  //calendar
  .p-datepicker {
    table {
      td {
        > span {
          &.p-highlight {
            color: $white;
            background: $primaryColor;

            &:hover {
              background-color: $primaryColorAlt;
              border: 2px solid $primaryColor;
            }
          }
        }

        &.p-datepicker-today {
          > span {
            &.p-highlight {
              color: $white;
              background: $primaryColor;
            }
          }
        }
      }
    }
  }

  //fieldset
  .p-fieldset {
    &.p-fieldset-toggleable {
      .p-fieldset-legend {
        &:hover {
          @include list-item-hover();
        }
      }
    }
  }

  //menubar
  .p-menubar {
    .p-menuitem-link {
      &:not(.p-disabled):hover {
        @include list-item-hover();
      }
    }

    .p-menubar-root-list {
      > .p-menuitem {
        > .p-menuitem-link {
          &:not(.p-disabled):hover {
            @include list-item-hover();
          }
        }
      }
    }
  }

  //tieredmenu
  .p-tieredmenu {
    .p-menuitem-link {
      &:not(.p-disabled):hover {
        @include list-item-hover();
      }
    }
  }

  //menu
  .p-menu {
    .p-menuitem-link {
      &:not(.p-disabled):hover {
        @include list-item-hover();
      }
    }
  }

  //contextmenu
  .p-contextmenu {
    .p-menuitem-link {
      &:not(.p-disabled):hover {
        @include list-item-hover();
      }
    }
  }

  //paginator
  .p-paginator {
    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
      &:not(.p-disabled):not(.p-highlight):hover {
        border: 1px solid $primaryColor;
      }
    }

    .p-paginator-pages {
      .p-paginator-page {
        &:not(.p-highlight):hover {
          border: 1px solid $primaryColor;
        }
      }
    }
  }

  //table
  .p-datatable {
    .p-sortable-column {
      &:not(.p-highlight):hover {
        @include list-item-hover();
      }

      &.p-highlight {
        background-color: $white300;

        &:hover {
          @include list-item-hover();
        }
      }
    }

    &.p-datatable-hoverable-rows {
      .p-datatable-tbody > tr:not(.p-highlight):hover {
        outline: 1px solid $primaryColorAlt;
      }
    }
  }

  //overlaypanel
  .p-overlaypanel {
    .p-overlaypanel-close {
      &:enabled:hover {
        background: $buttonHoverBg;
        color: $buttonTextHoverColor;
        border: 2px solid $buttonHoverBorderColor;
      }
    }
  }

  //picklist
  .p-picklist {
    .p-picklist-list {
      .p-picklist-item {
        &:not(.p-highlight):hover {
          @include list-item-hover();
        }
      }
    }
  }

  //tree
  .p-tree {
    &.p-tree-horizontal {
      .p-treenode {
        .p-treenode-content {
          &.p-treenode-selectable:not(.p-highlight):hover {
            background: $white;
            @include list-item-hover();
          }
        }
      }
    }
  }

  //treetable
  .p-treetable {
    .p-sortable-column {
      &:not(.p-highlight):hover {
        @include list-item-hover();
      }

      &.p-highlight {
        background-color: $white300;

        &:hover {
          background-color: $white;
          @include list-item-hover();
        }
      }

      &:focus {
        box-shadow: inset $focusShadow;
        outline: 0 none;
      }
    }

    &.p-treetable-hoverable-rows {
      .p-treetable-tbody > tr:not(.p-highlight):hover {
        outline: 1px solid $primaryColorAlt;
      }
    }
  }

  //megamenu
  .p-megamenu {
    .p-megamenu-root-list {
      > .p-menuitem {
        > .p-menuitem-link {
          &:not(.p-disabled):hover {
            @include list-item-hover();
          }
        }
      }
    }

    .p-menuitem-link {
      &:not(.p-disabled):hover {
        @include list-item-hover();
      }
    }
  }

  //panelmenu
  .p-panelmenu {
    .p-panelmenu-header {
      &:not(.p-highlight):not(.p-disabled) {
        > a:hover {
          @include list-item-hover();
        }
      }

      &.p-highlight {
        &:not(.p-disabled) {
          > a {
            &:hover {
              @include list-item-hover();
            }
          }
        }
      }
    }

    .p-panelmenu-content {
      .p-menuitem {
        .p-menuitem-link {
          &:not(.p-disabled):hover {
            @include list-item-hover();
          }
        }
      }
    }
  }

  //slidemenu
  .p-slidemenu {
    .p-menuitem-link {
      &:not(.p-disabled):hover {
        @include list-item-hover();
      }
    }
  }

  //carousel
  .p-carousel {
    .p-carousel-indicators {
      .p-carousel-indicator {
        &.p-highlight {
          button {
            background: $primaryColor;
            color: $white;
          }
        }
      }
    }
  }

  //galleria
  .p-galleria {
    .p-galleria-indicators {
      .p-galleria-indicator {
        &.p-highlight {
          button {
            background: $primaryColor;
            color: $white;
          }
        }
      }
    }
  }

  .p-datatable {
    .p-datatable-tbody {
      > tr {
        &.p-datatable-dragpoint-top > td {
          box-shadow: inset 0 2px 0 0 $primaryColor;
        }

        &.p-datatable-dragpoint-bottom > td {
          box-shadow: inset 0 -2px 0 0 $primaryColor;
        }
      }
    }
  }

  .p-selectbutton > .p-button,
  .p-togglebutton.p-button {
    transition: background-color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration;
  }
}
