html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-break: break-word;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-size: unset;
    font-weight: unset;
}
a {
    color: inherit;
    text-decoration: none;
}

.maxContentWidth {
    max-width: 820px;
    width: 100%;
}
